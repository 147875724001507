module.exports = {
  title: "Jason Lee",
  description: "Hello :) I'm Jason Lee and currently focusing on React.js.",
  author: "JasonLee",
  siteUrl: "https://sh981013s.github.io/",
  links: {
    github: "https://github.com/sh981013s",
    linkedIn: "https://www.rocketpunch.com/@sh981013s",
    facebook: "https://www.facebook.com/profile.php?id=100003531717909",
    instagram: "https://www.instagram.com/hwani_981013/",
    email: "mailto:sh981013s@gmail.com",
    etc: "https://www.notion.so/Hudi-s-R-sum-0c1d1a1b35284d1eaf05c5bfac4a3cad",
  },
  utterances: {
    repo: "sh981013s/sh981013s.github.io",
    type: "pathname",
  },
}
